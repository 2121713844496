export const DEBUG =
  process.env.NODE_ENV !== 'production' && !process.env.BRANCH;

export const PROD = process.env.NODE_ENV === 'production';

export const gaTrackingIds = {
};

export const ga4TrackingIds = {
  global_test: 'G-KPC1KH51FX',
  cn: 'G-S4EX53B760',
  global: 'G-S4EX53B760',
};

export const gtmTrackingIds = {
  ae: 'G-S4EX53B760',
  at: 'G-S4EX53B760',
  au: 'G-S4EX53B760',
  be: 'G-S4EX53B760',
  bh: 'G-S4EX53B760',
  ca: 'G-S4EX53B760',
  ch: 'G-S4EX53B760',
  cl: 'G-S4EX53B760',
  co: 'G-S4EX53B760',
  cz: 'G-S4EX53B760',
  de: 'G-S4EX53B760',
  dk: 'G-S4EX53B760',
  eg: 'G-S4EX53B760',
  es: 'G-S4EX53B760',
  fi: 'G-S4EX53B760',
  fr: 'G-S4EX53B760',
  gb: 'G-S4EX53B760',
  hr: 'G-S4EX53B760',
  hu: 'G-S4EX53B760',
  ie: 'G-S4EX53B760',
  il: 'G-S4EX53B760',
  in: 'G-S4EX53B760',
  it: 'G-S4EX53B760',
  jo: 'G-S4EX53B760',
  jp: 'G-S4EX53B760',
  kr: 'G-S4EX53B760',
  kw: 'G-S4EX53B760',
  ma: 'G-S4EX53B760',
  mx: 'G-S4EX53B760',
  my: 'G-S4EX53B760',
  nl: 'G-S4EX53B760',
  no: 'G-S4EX53B760',
  nz: 'G-S4EX53B760',
  om: 'G-S4EX53B760',
  ph: 'G-S4EX53B760',
  pl: 'G-S4EX53B760',
  pt: 'G-S4EX53B760',
  qa: 'G-S4EX53B760',
  ro: 'G-S4EX53B760',
  rs: 'G-S4EX53B760',
  sa: 'G-S4EX53B760',
  se: 'G-S4EX53B760',
  sg: 'G-S4EX53B760',
  si: 'G-S4EX53B760',
  sk: 'G-S4EX53B760',
  th: 'G-S4EX53B760',
  ua: 'G-S4EX53B760',
  us: 'G-S4EX53B760',
};

export const inhouseMarkets = [
  'ae',
  'at',
  'au',
  'be',
  'bh',
  'ca',
  'ch',
  'cn',
  'cl',
  'co',
  'cz',
  'de',
  'dk',
  'eg',
  'es',
  'fi',
  'fr',
  'gb',
  'hk',
  'hr',
  'hu',
  'id',
  'ie',
  'in',
  'il',
  'it',
  'jo',
  'jp',
  'kr',
  'kw',
  'lt',
  'ma',
  'my',
  'nl',
  'no',
  'ph',
  'pl',
  'pt',
  'qa',
  'ro',
  'rs',
  'sa',
  'se',
  'sg',
  'sk',
  'th',
  'tw',
  'ua',
  'us',
  'si',
  'mx',
  'om',
  'nz',
]

export const maskPostalCodeMarkets = [
  'ie',
  'kr',
  'gb',
];

export const [country, language] = process.env.SITE_FOLDER ? process.env.SITE_FOLDER.split('/') : ['f1','f1'];

export const crossDomains = [
  //'ikea.com',
  //'ww8.ikea.com',
  //'order.ikea.com',
  //'m.ikea.com',
  //'secure.ikea.com',
  //'securem.ikea.com',
  //'securema.ikea.com',
  //'accounts.ikea.com',
  //'fr.accounts.ikea.com',
  //'appointmentbooking.ikea.com',
  'ikea.ca',
  'ikeadt.com',
  'ikeacommunications.com',
  'ikea.com.au',
  'ikea.cn',
  'ikea.it',
  'ikea.pl',
  'ikea.de',
  'ikea.nl',
  'ikea.in',
  'ikea.dk',
  'ikea.se',
  'ikea.no',
  'ikea.jp',
  'ikea.ie',
  'ikea.ru',
  'ikeahomeview.dk',
  'paypal.com',
  'oppwa.com',
  'ikea-usa.com',
  'ikeasuomi.fi',
  'ikeafamily.fi',
  'ikeafamily.ca',
  'ikea-family.info',
  'ikea-event.tw',
  'keukenapparatuurkeuzehulp.nl',
  'hotel.ikea-event.tw',
  'italia.ikea.it',
  'surveygizmo.eu',
  'ww4.ikea.com/family-kiosk',
  'booking.live.nl.dovetail-tops.com',
  'homeideas.ikea.kr/new/',
  'planovanikuchyni.cz',
  'planovanikuchyni.hu',
  'planovanikuchyni.sk',
  'planovaniekuchyn.sk',
  'konyha-tervezes.hu',
  'secure.upc.ua',
  'planservice.ikea.nl',
  'ikea-family.ru',
  'brochure.ikea.it/IKEABusiness/',
  'secure.family.ikea.jp/',
  'business.ikea.jp/',
  'business.ikea.ru/',
  'servizio1.ikea.it/tuttoabordo/',
  'secure.upc.ua',
  //'country-zero.ikea.com/r1/',
  'lwc.newideas.kr/',
  'staging.admin.range-management.ingkadt.com/',
  'admin.range-management.ingka.com/',
  //'kitchenplanner.ikea.com/',
  //'kitchen.planner.ikea.com/',
  'static.hotjar.com/',
  'ikea.sapin.partner-keyneosoft.com',
  'ikea.food.partner-keyneosoft.com',
  'business.IKEA.com.sg',
  //]'equipment.ikea.com/',
  'partner.ingka.com',
  //'returns-us.ikea.com',
  'statuspage.sp.ingka.com/',
  'foodfacts.ikea.us/',
  'ikearenocations.jebbit.com/by1jfcce',
  //'ifb.ikea.com',
  //'h22.ikea.com/',
  //'officeplanner.ikea.com',
  //'de.giftcard-ikea.com/',
  //'biz.ikea.com',
  //'jp.accounts.ikea.com',
  //'checkout.ma.ikea.com',
  //'publications-pl-pl.ikea.com/',
  'ikea-value-quiz.ingka.com/',
  'publicera.ingka.com/',
  //'uk.forum.ikea.com',
  'navigera.ingka.com/',
  'km.ingka.com/',
  'store-capacity.ikea.net/stores',
  'kaskad.ingka.com/',
  //'confluence.build.ingka.ikea.com/',
  'rca.ingka.com/',
  
];

export const downloadFileTypes = ['doc', 'docx', 'pdf', 'txt'];

export const plpButtonElements = ['button', 'span'];

export const postMessageHosts = [];

export const errorLabels = {
  select_content: 'product was not found in list',
  view_item: 'clickedProduct was not found in session storage',
};
