import { sendEvent } from '@ingka-group/episod-client-js';

import {
  country as constantsCountry,
  language as constantsLanguage,
  inhouseMarkets,
} from '../constants';

import {
  getMultipleEventObjects,
  convertGAtoEpisod,
  removeNullsOrEmpty,
} from './helpers';

export const dispatch = (eventObj = {}, options = {}) => {
  try {
    const {
      initialPageView = false,
      PROD,
      country: optionsCountry,
      language: optionLanguage,
      keepalive = false,
      client_event_id,
    } = options;
    const country = optionsCountry || constantsCountry;
    const language = optionLanguage || constantsLanguage;

    if (!inhouseMarkets.includes(country)) {
      return;
    }

    const eventObjects = getMultipleEventObjects(eventObj, client_event_id);

    for (const eventObject of eventObjects) {
      const payload = convertGAtoEpisod(eventObject, { initialPageView, PROD, country, language });

      if (payload.event_name.includes('optimizely')) {
        return;
      }

      sendEvent(removeNullsOrEmpty(payload), { keepalive });
    }
  } catch (error) {
    console.error('Error in Episod dispatch', error);
  }
};
